import React from 'react';
import "../../styles/contact.css";
import { motion } from 'framer-motion';

function Contact() {
  return (
    <>
      <div className="contact-section">
        <div className="contact-overlay">
          <h1>Contact us</h1>
        </div>
      </div>

      <div className="contact-us-page">
        <div className="contact-details-row">
          <motion.div
            className="contact-info"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <p className="label">Address</p>
            <p>Ward no.11 LIC Road, Burhar Shahdol - 484110</p>
          </motion.div>

          <motion.div
            className="contact-info"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.3 }}
          >
            <p className="label">Email</p>
            <p>gyanniketanschool1986@gmail.com</p>
          </motion.div>

          <motion.div
            className="contact-info"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.6 }}
          >
            <p className="label">Phone</p>
            <p>07652315461</p>
          </motion.div>
        </div>

        <motion.div
          className="contact-map"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.9 }}
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3667.215615881563!2d82.09273447646358!3d23.208126113355217!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3981c276ab7f3c21%3A0x13b00de1f7fe90a8!2sBurhar%2C%20Madhya%20Pradesh%20484110!5e0!3m2!1sen!2sin!4v1698416003057!5m2!1sen!2sin"
            width="100%"
            height="300"
            allowFullScreen=""
            loading="lazy"
            title="Map Location"
          ></iframe>
        </motion.div>
      </div>
    </>
  )
}

export default Contact;