import React, { useState } from "react";
import "../../styles/enquiry.css";
import { motion } from "framer-motion";

function EnquiryForm() {

  const [formData, setFormData] = useState({
    childName: "",
    forClass: "",
    mobileNumber: "",
    email: "",
    guardianName: ""
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    alert("Enquiry submitted successfully!");
  };

  return (
    <>
      <div className="enquiry-section">
        <div className="enquiry-overlay">
          <h1>Enquiry</h1>
        </div>
      </div>

      <div className="enquiry-form-section">
        <motion.div
          className="enquiry-form-container"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h2>Enquiry Form</h2>
          <form onSubmit={handleSubmit} className="enquiry-form">
            {["childName", "mobileNumber", "email", "guardianName"].map((field, index) => (
              <motion.div
                key={field}
                className="form-group"
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1, duration: 0.5 }}
              >
                <label>
                  {field === "childName" ? "Name of Child" :
                    field === "mobileNumber" ? "Mobile Number" :
                      field === "email" ? "Email" : "Guardian's Name"}
                </label>
                <motion.input
                  type={field === "mobileNumber" ? "tel" : field === "email" ? "email" : "text"}
                  name={field}
                  value={formData[field]}
                  onChange={handleChange}
                  whileFocus={{ scale: 1.05 }}
                  transition={{ duration: 0.2 }}
                  required
                />
              </motion.div>
            ))}

            <motion.div
              className="form-group"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 3 * 0.1, duration: 0.5 }} // Add delay for the dropdown
            >
              <label htmlFor="forClass">For Class:</label>
              <motion.select
                name="forClass"
                id="forClass"
                value={formData.forClass}
                onChange={handleChange}
                whileFocus={{ scale: 1.05 }}
                transition={{ duration: 0.2 }}
                required
              >
                <option value="" disabled selected>Select Class</option>
                <option value="LKG">LKG</option>
                <option value="UKG">UKG</option>
                <option value="Class 1">Class 1</option>
                <option value="Class 2">Class 2</option>
                <option value="Class 3">Class 3</option>
                <option value="Class 4">Class 4</option>
                <option value="Class 5">Class 5</option>
                <option value="Class 6">Class 6</option>
                <option value="Class 7">Class 7</option>
                <option value="Class 8">Class 8</option>
                <option value="Class 9">Class 9</option>
                <option value="Class 10">Class 10</option>
                <option value="Class 11">Class 11</option>
                <option value="Class 12">Class 12</option>
              </motion.select>
            </motion.div>

            <motion.button
              type="submit"
              whileHover={{ scale: 1.05, backgroundColor: "#0047AB" }}
              whileTap={{ scale: 0.95 }}
              transition={{ duration: 0.3 }}
            >
              Submit
            </motion.button>
          </form>
        </motion.div>
      </div>
    </>
  )
}

export default EnquiryForm;