import React from 'react';
import "../../styles/achievement.css";
import { motion } from 'framer-motion';

import achievementimg1 from "../Assets/achievment1.jpeg";
import achievementimg2 from "../Assets/achievment2.jpeg";
import achievementimg3 from "../Assets/achievment3.jpeg";
import achievementimg4 from "../Assets/achievment4.jpeg";
import achievementimg5 from "../Assets/achievment5.jpeg";
import achievementimg6 from "../Assets/achievment6.jpeg";
import achievementimg7 from "../Assets/achievment7.jpeg";
import achievementimg8 from "../Assets/achievment8.jpeg";
import achievementimg9 from "../Assets/achievment9.jpeg";
import achievementimg10 from "../Assets/achievment10.jpeg";
import achievementimg11 from "../Assets/achievment11.jpeg";
import achievementimg12 from "../Assets/achievment12.jpeg";


const achievements = [
  {
    title: 'Best School in Region',
    description: 'Awarded the Best School in the region for academic excellence and extracurricular activities.',
    image: achievementimg1,
  },
  {
    title: '100% Exam Pass Rate',
    description: 'Our students achieved a 100% pass rate in the recent board exams.',
    image: achievementimg2,
  },
  {
    title: 'Science Fair Champions',
    description: 'First place in the National Science Fair with innovative projects by our students.',
    image: achievementimg3,
  },
  {
    title: 'Best School in Region',
    description: 'Awarded the Best School in the region for academic excellence and extracurricular activities.',
    image: achievementimg4,
  },
  {
    title: '100% Exam Pass Rate',
    description: 'Our students achieved a 100% pass rate in the recent board exams.',
    image: achievementimg5,
  },
  {
    title: 'Science Fair Champions',
    description: 'First place in the National Science Fair with innovative projects by our students.',
    image: achievementimg6,
  },
  {
    title: 'Best School in Region',
    description: 'Awarded the Best School in the region for academic excellence and extracurricular activities.',
    image: achievementimg7,
  },
  {
    title: '100% Exam Pass Rate',
    description: 'Our students achieved a 100% pass rate in the recent board exams.',
    image: achievementimg8,
  },
  {
    title: 'Science Fair Champions',
    description: 'First place in the National Science Fair with innovative projects by our students.',
    image: achievementimg9,
  },
  {
    title: 'Best School in Region',
    description: 'Awarded the Best School in the region for academic excellence and extracurricular activities.',
    image: achievementimg10,
  },
  {
    title: '100% Exam Pass Rate',
    description: 'Our students achieved a 100% pass rate in the recent board exams.',
    image: achievementimg11,
  },
  {
    title: 'Science Fair Champions',
    description: 'First place in the National Science Fair with innovative projects by our students.',
    image: achievementimg12,
  },
];

function Achievement() {
  return (
    <>
      <div className="achievement-page-section">
        <div className="achievement-page-overlay">
          <h1>Achievement</h1>
        </div>
      </div>

      <div className="achievement-page">
        <motion.h1
          className="achievement-title"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          Our Achievements
        </motion.h1>

        <div className="achievement-grid">
          {achievements.map((achievement, index) => (
            <motion.div
              key={index}
              className="achievement-card"
              initial={{ opacity: 0, scale: 0.9 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              viewport={{ once: true }}
            >
              <img src={achievement.image} alt={achievement.title} className="achievement-image" />
              <h2 className="achievement-card-title">{achievement.title}</h2>
              <p className="achievement-card-description">{achievement.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </>
  )
}

export default Achievement;