import React from 'react';
import "../../styles/gallery.css";
import { motion } from 'framer-motion';

import img1 from "../Assets/gallery1.jpeg";
import img2 from "../Assets/gallery2.jpeg";
import img3 from "../Assets/gallery3.jpeg";
import img4 from "../Assets/gallery4.jpeg";
import img5 from "../Assets/gallery5.jpeg";
import img6 from "../Assets/gallery6.jpeg";
import img7 from "../Assets/gallery7.jpeg";
import img8 from "../Assets/gallery8.jpeg";
import img9 from "../Assets/gallery9.jpeg";
import img10 from "../Assets/gallery10.jpeg";
import img11 from "../Assets/gallery11.jpeg";
import img12 from "../Assets/gallery12.jpeg";
import img13 from "../Assets/gallery13.jpeg";
import img14 from "../Assets/gallery14.jpeg";
import img15 from "../Assets/gallery15.jpeg";
import img16 from "../Assets/gallery16.jpeg";
import img17 from "../Assets/gallery17.jpeg";
import img18 from "../Assets/gallery18.jpeg";
import img19 from "../Assets/gallery19.jpeg";
import img20 from "../Assets/gallery20.jpeg";
import img21 from "../Assets/gallery21.jpeg";
import img22 from "../Assets/gallery22.jpeg";
import img23 from "../Assets/gallery23.jpeg";

import video1 from "../Assets/video1.mp4";
import video2 from "../Assets/video2.mp4";
import video3 from "../Assets/video3.mp4";
import video4 from "../Assets/video4.mp4";
import video5 from "../Assets/video5.mp4";
import video6 from "../Assets/video6.mp4";
import video7 from "../Assets/video7.mp4";


const mediaItems = [
  { type: 'image', src: img1, alt: 'School Event 1' },
  { type: 'video', src: video1, alt: 'School Event Video 1' },
  { type: 'image', src: img12, alt: 'School Event 2' },
  { type: 'video', src: video2, alt: 'School Event Video 2' },
  { type: 'image', src: img3, alt: 'School Event 1' },
  { type: 'video', src: video3, alt: 'School Event Video 1' },
  { type: 'image', src: img18, alt: 'School Event 2' },
  { type: 'video', src: video4, alt: 'School Event Video 2' },
  { type: 'image', src: img15, alt: 'School Event 1' },
  { type: 'video', src: video5, alt: 'School Event Video 1' },
  { type: 'image', src: img17, alt: 'School Event 2' },
  { type: 'video', src: video6, alt: 'School Event Video 2' },
  { type: 'image', src: img2, alt: 'School Event 1' },
  { type: 'image', src: img3, alt: 'School Event 1' },
  { type: 'image', src: img4, alt: 'School Event 1' },
  { type: 'image', src: img5, alt: 'School Event 1' },
  { type: 'image', src: img6, alt: 'School Event 1' },
  { type: 'image', src: img7, alt: 'School Event 1' },
  { type: 'video', src: video7, alt: 'School Event Video 2' },
  { type: 'image', src: img8, alt: 'School Event 1' },
  { type: 'image', src: img9, alt: 'School Event 1' },
  { type: 'image', src: img10, alt: 'School Event 1' },
  { type: 'image', src: img11, alt: 'School Event 1' },
  { type: 'image', src: img13, alt: 'School Event 1' },
  { type: 'image', src: img14, alt: 'School Event 1' },
  { type: 'image', src: img16, alt: 'School Event 1' },
  { type: 'image', src: img19, alt: 'School Event 1' },
  { type: 'image', src: img20, alt: 'School Event 1' },
  { type: 'image', src: img21, alt: 'School Event 1' },
  { type: 'image', src: img22, alt: 'School Event 1' },
  { type: 'image', src: img23, alt: 'School Event 1' },
];


function Gallery() {

  return (
    <>
      <div className="gallery-section-page">
        <div className="gallery-overlay">
          <h1>Gallery</h1>
        </div>
      </div>

      <div className="gallery-container">
        <motion.h1
          className="gallery-title"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          School Memories
        </motion.h1>

        <div className="media-grid">
          {mediaItems.map((item, index) => (
            <motion.div
              key={index}
              className="media-item"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              whileHover={{ scale: 1.05 }}
            >
              {item.type === 'image' ? (
                <motion.img
                  src={item.src}
                  alt={item.alt}
                  className="gallery-media"
                />
              ) : (
                <motion.video
                  src={item.src}
                  controls
                  className="gallery-media"
                />
              )}
            </motion.div>
          ))}
        </div>
      </div>

    </>
  )
}

export default Gallery;