import React from 'react';
import "../../styles/faculty.css";
import { motion } from 'framer-motion';

import principle from "../Assets/principle.jpeg";
import teacher1 from "../Assets/teacher1.jpeg";
import teacher2 from "../Assets/teacher2.jpeg";
import teacher3 from "../Assets/teacher3.jpeg";
import teacher4 from "../Assets/teacher4.jpeg";
import teacher5 from "../Assets/teacher5.jpeg";
import teacher6 from "../Assets/teacher6.jpeg";
import teacher7 from "../Assets/teacher7.jpeg";
import teacher8 from "../Assets/teacher8.jpeg";
import teacher9 from "../Assets/teacher9.jpeg";
import teacher10 from "../Assets/teacher10.jpeg";
import teacher11 from "../Assets/teacher11.jpeg";
import teacher12 from "../Assets/teacher12.jpeg";
import teacher13 from "../Assets/teacher13.jpeg";
import teacher14 from "../Assets/teacher14.jpeg";

const facultyList = [
  {
    image: principle,
    name: 'MR.BALMIK PRASAD SHARMA',
    position: 'PRINCIPLE',
    school: 'GYAN NIKETAN BURHAR',
  },
  {
    image: teacher1,
    name: 'MR.ARUN KUMAR AWADHIYA ',
    position: ' CHAIRMAN ',
    school: 'GYAN NIKETAN BURHAR',
  },
  {
    image: teacher8,
    name: 'MR.PREM KUMAR PANDEY',
    position: 'VICE PRINCIPAL',
    school: 'GYAN NIKETAN BURHAR',
  },
  {
    image: teacher2,
    name: 'MRS.SHYAMA TIWARI',
    position: 'EXAM INCHARGE',
    school: 'GYAN NIKETAN BURHAR',
  },
  {
    image: teacher3,
    name: 'MR.RAJESH KUMAR MISHRA ',
    position: 'TEACHER',
    school: 'GYAN NIKETAN BURHAR',
  },
  {
    image: teacher4,
    name: 'MR.SHARAD KUMAR TAMRAKAR',
    position: 'TEACHER',
    school: 'GYAN NIKETAN BURHAR',
  },
  {
    image: teacher5,
    name: 'MR.AMBIKA PRASAD CHATURVEDI',
    position: 'LECTURAR',
    school: 'GYAN NIKETAN BURHAR',
  },
  {
    image: teacher6,
    name: 'Mr.RAJEEV KUMAR SHARMA',
    position: 'LECTURAR',
    school: 'GYAN NIKETAN BURHAR',
  },
  {
    image: teacher7,
    name: 'MR.MOHAN MOTWANI',
    position: 'LECTURAR IN ACCOUNT ',
    school: 'GYAN NIKETAN BURHAR',
  },
  {
    image: teacher9,
    name: 'MR.SHASHI BHUSHAN TRIPATHI',
    position: 'LECTURAR IN BIO',
    school: 'GYAN NIKETAN BURHAR',
  },
  {
    image: teacher10,
    name: 'MRS.SHILPA SHARMA',
    position: 'LECTURAR IN CHEMISTRY',
    school: 'GYAN NIKETAN BURHAR',
  },
  {
    image: teacher11,
    name: 'MR.RAM PRAMOD DWIVEDI ',
    position: 'OFFICE CLERK ',
    school: 'GYAN NIKETAN BURHAR',
  },
  {
    image: teacher12,
    name: 'MRS.SITA SONI',
    position: 'COMPUTER TEACHER ',
    school: 'GYAN NIKETAN BURHAR',
  },
  {
    image: teacher13,
    name: 'MR.ASHOK KUMAR GUPTA',
    position: 'LECTURAR IN HINDI',
    school: 'GYAN NIKETAN BURHAR',
  },
  {
    image: teacher14,
    name: 'MISS.PRIYANSHI GUPTA',
    position: 'PRIMARY TEACHER',
    school: 'GYAN NIKETAN BURHAR',
  },
];

function Faculty() {
  return (
    <>
      <div className="faculty-page-section">
        <div className="faculty-page-overlay">
          <h1>Faculty</h1>
        </div>
      </div>

      <div className="faculty-page">
        <motion.h1
          className="faculty-title"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          Our Team Members
        </motion.h1>

        <div className="faculty-grid">
          {facultyList.map((faculty, index) => (
            <motion.div
              key={index}
              className="faculty-card"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              viewport={{ once: true }}
            >
              <img src={faculty.image} alt={faculty.name} className="faculty-image" />
              <h2 className="faculty-name">{faculty.name}</h2>
              <p className="faculty-position">{faculty.position}</p>
              <p className="faculty-school">{faculty.school}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </>
  )
}

export default Faculty;