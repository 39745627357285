import React from 'react';
import "../../styles/about.css";
import { motion } from "framer-motion";

import aboutimg from "../Assets/gallery8.jpeg";
import aboutimg1 from "../Assets/gallery44.jpeg";

function About() {
  return (
    <>
      <div className="aboutus-section">
        <div className="aboutus-overlay">
          <h1>About us</h1>
        </div>
      </div>

      <section className="about-us">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-6">
              <h6 className="about-title">About Gyan Niketan</h6>
              <p className="about-description">
                Gyan Niketan English medium school is founded in 1986. It is owned and managed by Gyan Niketan Shikshan Sammittee Burhar.
              </p>

              <h6 className="section-title">VISION</h6>
              <p className="about-description">
                All-round development of a child into a whole person to fulfill their role in life as a faithful citizen of the country.
              </p>

              <h6 className="section-title">MISSION</h6>
              <p className="about-description">
                The spiritual, moral, intellectual, and physical energies of students are mobilized to empower them to face a challenging future successfully.
              </p>
            </div>
            <div className="col-sm-6">
              <div className="about-page-img">
                <img src={aboutimg} alt="About Gyan Niketan" />
              </div>
            </div>
          </div>

          <div className="aboutnext-section">
            <div className="container">
              <div className="row">
                <div className="col-md-3 col-sm-6 text-center">
                  <div className="about-feature">
                    <h6 className="section-title">Exhibition</h6>
                    <p className="aboutnext-description">
                      Artistic, cultural, and scientific exhibitions are held to give expression to the creativity and scientific aptitude of the students.
                    </p>
                  </div>
                </div>

                <div className="col-md-3 col-sm-6 text-center">
                  <div className="about-feature">
                    <h6 className="section-title">Library</h6>
                    <p className="aboutnext-description">
                      There is a library with a rich collection of books along with the school office, to develop the reading skills of students.
                    </p>
                  </div>
                </div>

                <div className="col-md-3 col-sm-6 text-center">
                  <div className="about-feature">
                    <h6 className="section-title">Celebrations</h6>
                    <p className="aboutnext-description">
                      With a variety of programs, natural and religious festivals are celebrated.
                    </p>
                  </div>
                </div>

                <div className="col-md-3 col-sm-6 text-center">
                  <div className="about-feature">
                    <h6 className="section-title">Scouts and Guides</h6>
                    <p className="aboutnext-description">
                      To develop feelings of patriotism and respect for the nation, the school has scouts and guides for students.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="aboutus-next-section">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-sm-12">
                  <h6 className="about-title">Co-Operation of the Parents</h6>
                  <p className="about-description">
                    Parents are expected to cooperate with the management in maintaining discipline and the standard of the school.
                    <br /><br />
                    The unit test reports and quarterly, half-yearly results should be scrutinized and signed by parents. The school will not be responsible if students return the progress card to the class teacher with fake signatures of their parents or guardians.
                    <br /><br />
                    The school does not guarantee the success of students' studies unless his/her parents/guardians cooperate with the school authorities and do their part by enforcing regularity and discipline. They must see their lessons daily. They shall insist on punctuality and regularity in attending class.
                    <br /><br />
                    Parents/Guardians/Visitors are not permitted to walk into classrooms or interview the teachers during class time. They are requested to bring their complaints directly to the principal and not to any member of the staff.
                    <br /><br />
                    Parents and guardians are requested to meet the class teacher at least once a month on the last working day, i.e., the 30th or 31st of every month.
                  </p>

                  <h6 className="about-title">GOAL "Life Is For Benevolence"</h6>
                  <p className="about-description">In this advance and competitive age when people struggle to find meaning and value of life. This school makes an earnest effort to instill knowledge and values through the academic and moral educational pattern through the motto "life is for benevolence, school prepares her students to live their lives meaning full and to make efforts to do work for others in accordance after the example of "River Trees and cow".</p>

                  <h6 className="about-title">Admission</h6>
                  <p className="about-description">Admission to school is restricted to the seats available. It is open to all the people without distinction to any religion cast, creed and colour 25% seats are reserved for deprived class pupils/BPL/or other reserved classes under the provision to RTE, in beginning class, i.e. L.K.G only.
                    <br /><br />
                    Those who seek admission from other schools to any class are subjected to competitive entrance test to prove their fitness.
                    <br /><br />
                    Parents will have to submit transfer certificate photo copy of marksheet and birth certificate in support of the date of birth entered in the admission form. In the date of birth as recorded by the authorties shall be considered legal and no change or alteration will be allowed later.
                  </p>
                  <h6 className="about-title">Contributions</h6>
                  <p className="about-description">Contributions are fixed by the managing committee and are subjected to revision fond necessary. This may be paid lump sum or in installment on working days from 8.30 to 1.30 P.M. Defaulters will have to fine, No student will be allowed to sit for exams unless all dues are fully paid, Parents will kindly keep the receipts issued to them and will produce if/needed for next payment, contributions must be paid even if the pupil has been absent during month</p>

                  <h6 className="about-title">Examinations and Promotions</h6>
                  <p className="about-description">There will be frequent class tests, 6 unit tests, Quarterly and half yearly and final exam as per due time. The parents are expected to sign and return progress card to school after every unit test sent to them through their words. Students will have to appear in all examinations. There will be no anticipation or post-ponement of any of the exam, any student who is absent from a unit test with due permission of principal, will be give average marks to qualify test, promotions are granted on the average of marks required in unit tests. Quarterly, half yearly and annual examinations respectively.</p>

                  <h6 className="about-title">Parent Teacher's Meet</h6>
                  <p className="about-description">Parents-teachers meeting is organized after each exam to keep the parent in touch after with the performance of their ward.
                    <br /><br />
                    Participation in Games and other co-curricular activities:-

                    To develop an all round personality the students are encouraged to take part in games and co-curricular activities organized in the school.
                    <br /><br />

                    No student will be exempted from school games and other activities without a doctor's certificate attendance at official school function like Independence day, Republic day, Sports day etc are compulsory.</p>

                </div>
              </div>
            </div>
          </div>

          <div className="uniform-section">
            <div className="container">
              <h6 className="section-title">Uniform</h6>

              <div className="row">
                <div className="col-sm-6">
                  <h6 className="sub-title">Pre-Primary</h6>
                  <p>Blue Colour Blazer, Yellow Shirt, Black Pant, White Socks, Black Shoes</p>
                </div>
                <div className="col-sm-6">
                  <h6 className="sub-title">For Boys</h6>
                  <p><strong>Monday, Tuesday, Thursday, Friday:</strong> Check Shirt, Dark Grey Full Pant, Grey Tie, Black Shoes & Grey Socks.</p>
                  <p><strong>Wednesday and Saturday:</strong> House Dress.</p>
                  <p><strong>Winter:</strong> Grey Blazer as per colour of full pant.</p>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6">
                  <h6 className="sub-title">For Girls (1st to 5th)</h6>
                  <p><strong>Monday, Tuesday, Thursday, Friday:</strong> Check Shirt, Grey Tunic & Leggings, Grey Tie, Black Shoes, Grey Socks.</p>
                  <p><strong>Wednesday and Saturday:</strong> White Full Shirt, White Tunic, Grey Tie, White Shoes, White Socks.</p>
                </div>
                <div className="col-sm-6">
                  <h6 className="sub-title">For Girls (6th to 12th)</h6>
                  <p><strong>Monday, Tuesday, Thursday, Friday:</strong> Check V-neck Stand Collar Kurta, Grey Salwar & Dupatta, Black Shoes, Grey Socks.</p>
                  <p><strong>Wednesday and Saturday:</strong> White V-neck Stand Collar Kurta, White Salwar and Dupatta, White Shoes, White Socks, White Ribbon (House Dress).</p>
                </div>
              </div>

              <div className="winter-uniform">
                <h6 className="sub-title">Winter Uniform</h6>
                <p>Besides respective uniform, Dark Grey Blazer from Primary onwards.</p>
              </div>
            </div>
          </div>


          <div className="school-hours-section">
            <div className="container">
              <h6 className="section-title">School and Office Hours</h6>

              <div className="row">
                <div className="col-sm-3">
                  <h6 className="sub-title">School Time</h6>
                  <p>8.30 A.M. to 2.00 P.M. [Except K.G. Classes]</p>
                </div>

                <div className="col-sm-3">
                  <h6 className="sub-title">Principal Time</h6>
                  <p>9.00 A.M. to 10.30 A.M.</p>
                </div>

                <div className="col-sm-3">
                  <h6 className="sub-title">For Primary Classes</h6>
                  <p>8.30 A.M. to 1.00 P.M.</p>
                </div>

                <div className="col-sm-3">
                  <h6 className="sub-title">Winter Timings</h6>
                  <p>9.00 A.M. to 2.30 P.M.</p>
                  <p><strong>Principal:</strong> 9.30 A.M. to 11.00 A.M.</p>
                  <p><strong>For Primary:</strong> 9.00 A.M. to 1.30 P.M.</p>
                </div>
              </div>

              <p className="note">Note: There is a half working day usually on 30 or 31st of every month.</p>

              <div className="curriculum-info">
                <h6 className="section-title">Curriculum</h6>
                <p>The school is affiliated to BSEMP Bhopal.</p>
                <p>The medium of instruction is English.</p>
                <p>The school offers streams: Math's, Science, and Commerce.</p>
                <p>II and III languages are Hindi and Sanskrit.</p>
              </div>
            </div>
          </div>



        </div>
      </section>

    </>
  )
}

export default About;