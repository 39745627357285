import React, { useState, useEffect } from "react";
import "../styles/header.css";
import { FiMenu, FiX } from "react-icons/fi";
import logo from "../Components/Assets/logo.png"; 

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className={`header ${scrolled ? "scrolled" : ""}`}>
      <div className="logo">
        <img src={logo} alt="Logo" height={80} width={80} />
      </div>
      <nav className={`nav ${menuOpen ? "nav-open" : ""}`}>
        <ul>
          <li><a href="/">Home</a></li>
          <li><a href="/about">About us</a></li>
          <li><a href="/faculty">Faculty</a></li>
          <li><a href="/achievement">Achievements</a></li>
          <li><a href="/booklist">BookList</a></li>
          <li><a href="/gallery">Gallery</a></li>
          <li><a href="/contact">Contact Us</a></li>
        </ul>
      </nav>
      <a href="/enquiry">
      <button className="header-button">Enquiry Now</button>
      </a>
      <div className="menu-icon" onClick={toggleMenu}>
        {menuOpen ? <FiX size={30} /> : <FiMenu size={30} />}
      </div>
    </header>
  );
};

export default Header;
