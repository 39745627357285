import React from 'react';
import "../../styles/booklist.css";
import { motion } from 'framer-motion';

import class1pdf from "../Assets/class 1.pdf";
import class2pdf from "../Assets/class 2.pdf";
import class3pdf from "../Assets/class 3.pdf";
import class4pdf from "../Assets/class 4.pdf";
import class5pdf from "../Assets/class 5.pdf";
import class6pdf from "../Assets/class 6.pdf";
import class7pdf from "../Assets/class 7.pdf";
import class8pdf from "../Assets/class 8.pdf";
import class9and10pdf from "../Assets/Class9and10.pdf";
import class11and12commercepdf from "../Assets/class 11& 12 commerce.pdf";
import class11and12pdf from "../Assets/class 11&12.pdf";

const bookList = [
    {
        className: 'Class I',
        pdflink: class1pdf,
    },
    {
        className: 'Class II',
        pdflink: class2pdf,
    },
    {
        className: 'Class III',
        pdflink: class3pdf,
    },
    {
        className: 'Class IV',
        pdflink: class4pdf,
    },
    {
        className: 'Class V',
        pdflink: class5pdf,
    },
    {
        className: 'Class VI',
        pdflink: class6pdf,
    },
    {
        className: 'Class VII',
        pdflink: class7pdf,
    },
    {
        className: 'Class VIII',
        pdflink: class8pdf,
    },
    {
        className: 'CLASS IX & X',
        pdflink: class9and10pdf,
    },
    {
        className: 'CLASS XI & XII (COMMERCE)',
        pdflink: class11and12commercepdf,
    },
    {
        className: 'CLASS XI & XII (SCIENCE)',
        pdflink: class11and12pdf,
    },
];

function Booklist() {
    return (
        <>
            <div className="booklist-section">
                <div className="booklist-overlay">
                    <h1>Booklist</h1>
                </div>
            </div>

            <div className="booklist-page">
                <motion.h1
                    className="booklist-title"
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                >
                    Book List
                </motion.h1>

                <div className="booklist-grid">
                    {bookList.map((book, index) => (
                        <motion.div
                            key={index}
                            className="book-card"
                            initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: index * 0.2 }}
                            viewport={{ once: true }}
                        >
                            <h2 className="book-class-name">{book.className}</h2>
                            <a href={book.pdflink} className="view-details-button">
                                View Details
                            </a>
                        </motion.div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default Booklist;