import React from 'react';
import "../styles/footer.css";
import logo from "../Components/Assets/logo.png"

function Footer() {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row">
            {/* <div className="footer-columns"> */}
            <div className="footer-column col-sm-4">
              <img src={logo} alt="School Logo" className="footer-logo" />
            </div>
            <div className="footer-column col-sm-4">
              <h3 className="footer-title">Get in touch</h3>
              <p className="footer-address">Ward no.11 LIC Road, Burhar Shahdol - 484110</p>
              <p className="footer-address">Phone:  07652315461</p>
              <p className="footer-address">Email: gyanniketanschool1986@gmail.com</p>
            </div>
            <div className="footer-column col-sm-4">
              <h3 className="footer-title">Quick Links</h3>
              <ul className="footer-links">
                <li><a href="/about">About Us</a></li>
                <li><a href="/">Admissions</a></li>
                <li><a href="/contact">Contact</a></li>
              </ul>
            </div>
          </div>
          {/* </div> */}
          <div className="footer-bottom">
            <p>© 2024 Gyanniketan Burhar. All Rights Reserved.</p>
          </div>
        </div>
      </footer>

    </>
  )
}

export default Footer;